/* Header Component Styles */
@use '../../../../../scss/theme' as *;
@use '../../../../../scss/utils/media' as *;

.mobile-menu-container{
    visibility: hidden;
    position: fixed;
    z-index: 100;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background: $themeBlack;
    @include media_large{
        display: none;
    }
    .mobile-menu{
        .header{
            padding: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .mobile-menu-btn-container{
                @include center_content;
                .mobile-menu-btn{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 32px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    align-items: center;
                    background: none;
                    border: none;
                    &:hover{
                        cursor: pointer;
                    }
                    .btn-bar{
                        width: 33px;
                        height: 4px;
                        margin: 3px 0;
                        border-radius: 3px;
                        background: $themeBlack;
                        transform-origin: center;
                        transition: 0.5s all;
                        &.close-btn-bar{
                            position: absolute;
                            &:nth-of-type(1){
                                transform: rotate(-45deg);
                            }
                            &:nth-of-type(2){
                                opacity: 0;
                                transform: rotate(-45deg);
                            }
                            &:nth-of-type(3){
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
            }
        }
        .links-container{
            .links-list{
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: left;
                .link-item{
                    .nav-link{
                        padding: 16px;
                        padding-left: 32px;
                        display: block;
                        font-size: 1.25em;
                        color: $textColorWhite;
                        text-decoration: none;
                        text-transform: capitalize;
                        &:hover{
                            color: $themeColorDark;
                            background: black;
                        }
                    }
                    .active{
                        color: $themeColorDark;
                        text-decoration: underline;
                        &:hover{
                            color: $themeColorDark;
                            background: none;
                        }
                    }
                }
            }
            .darkmode-toggle-container{
                padding: 16px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}


/* THEME COLORS */
body{
    &.lightTheme{ 
        .mobile-menu-container{
            background: $themeWhite;
            .mobile-menu{
                .header{
                    .mobile-menu-btn-container{
                        &:hover{
                            .mobile-menu-btn{
                                .btn-bar{
                                    background: $themeColor;
                                }
                            }
                        }
                        .mobile-menu-btn{
                            .btn-bar{
                                background: $themeBlack;
                            }
                        }
                    }
                }
                .links-container{
                    .links-list{
                        .link-item{
                            .nav-link{
                                color: $textColorBlack;
                                &:hover{
                                    color: $themeColor;
                                    background: white;
                                }
                            }
                            .active{
                                color: $themeColor;
                                &:hover{
                                    color: $themeColor;
                                    background: none;
                                }
                            }
                        }
                    }
                    .darkmode-toggle-container{
                    }
                }
            }
        }
    }
    &.darkTheme{  
        .mobile-menu-container{
            background: $themeBlack;
            .mobile-menu{
                .header{
                    .mobile-menu-btn-container{
                        &:hover{
                            .mobile-menu-btn{
                                .btn-bar{
                                    background: $themeColorDark;
                                }
                            }
                        }
                        .mobile-menu-btn{
                            .btn-bar{
                                background: $themeWhite;
                            }
                        }
                    }
                }
                .links-container{
                    .links-list{
                        .link-item{
                            .nav-link{
                                color: $textColorWhite;
                                &:hover{
                                    color: $themeColorDark;
                                    background: black;
                                }
                            }
                            .active{
                                color: $themeColorDark;
                                &:hover{
                                    color: $themeColorDark;
                                    background: none;
                                }
                            }
                        }
                    }
                    .darkmode-toggle-container{
                    }
                }
            }
        }
    }
}


