/* Theme Imports */
@use 'theme' as *;

/* global imports */
@import './utils/config';
@import './utils/media';
@import './utils/animations';

/* global resets */
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,body{
    margin: 0;
    padding: 0;
}

a, button, .site_link{ 
    &:hover{
        cursor: pointer;
    }
    transition: 0.25s color;
}

p, .site_paragraph{
    margin: 32px 0;
    font-size: 24px;
    line-height: 32px;
}

.text-highlight{
    font-weight: bold;
}

.flex-img{
    width: 100%;
    height: auto;
}

.site_content_container, .page-container{
    visibility: hidden;
    position: relative;
    margin: 0 auto;
    padding-top: 100px;
    @include media_large{
        padding-top: 0;
    }
    min-height: 90vh;
    max-width: $themeMaxWidth;
}
.site_page_heading, .page-heading{
    padding-bottom: 32px;
    margin-bottom: 16px;
    position: relative;
    font-size: 48px;
    text-align: left;

    &:after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 10px;
        width: 200px;
        background: $themeColor;
    }
}
.site_page_subheading, .page-subheading{
    font-size: $sub_heading_font_size;
}



code{
    font-family: monospace, sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 8px 16px;
    border-radius: 8px;
}

body{
    max-width: 100%;
    min-height: 101vh;
    position: relative;
    overflow-x: hidden;
    min-height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    transition: background 0.25s;
    font-family: 'Archivo', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    .main-content{
        position: relative;
        z-index: 2;
        .main-container{
            width: 100%;
            padding: 32px;
            margin: auto;
            max-width: $themeMaxWidth;
            @include media_small{
                padding: 32px 8px;
            }
        }
    }
}


/* THEME COLORS */
body{
    &.lightTheme{ 
        background: linear-gradient(45deg, $themeColorFade, rgba(0,0,0,0), $themeColorFade);
        background-color: $themeWhite;
        color: $textColorBlack;
        .site_link, a{
            color: $linkColor;
            &:hover{
                color: $linkColorHover;
            }
            &:visited{
                color: $linkColorVisited;
            }
        }
        .site_content_container{ color: $textColorBlack; }
        .site_page_heading{ color: $textColorBlack; }
        .site_paragraph{ color: $textColorBlack; }

    }
    &.darkTheme{  
        background: linear-gradient(45deg, $themeColorFadeDark, rgba(0,0,0,0), $themeColorFadeDark);
        background-color: $themeBlack;
        color: $textColorWhite;
        .site_link, a{
            color: $linkColorDark;
            &:hover{
                color: $linkColorHoverDark;
            }
            &:visited{
                color: $linkColorVisitedDark;
            }
        }
        .site_content_container{ color: $textColorWhite; }
        .site_page_heading{ color: $textColorWhite; }
        .site_paragraph{ color: $textColorWhite; }
    }
}
