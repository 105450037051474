// colors
$themeColor:  rgb(0,155,155);
$themeColorDark:  rgb(0,175,175);
$themeColor2:  rgb(155,0,0);
$themeColor2Dark:  rgb(200,0,0);
$themeColorFade:  rgba(0,175,175, 0.1);
$themeColorFadeDark:  rgba(0,175,175, 0.1);
$themeBlack:  rgb(20,20,20);
$themeWhite:  rgb(230,230,230);

$textColorWhite:  rgb(230,230,230);
$textColorBlack:  rgb(20,20,20);

$linkColor:               $themeColor;
$linkColorDark:           $themeColorDark;
$linkColorHover:          rgb(23, 67, 130);
$linkColorHoverDark:      rgb(38, 109, 211);
$linkColorVisited:        $themeColor2;
$linkColorVisitedDark:    $themeColor2Dark;
$linkBgHover:             rgba(20,20,20, 0.1);
$linkBgHoverDark:         rgba(230,230,230, 0.1);


$shadow: 0 0 10px 5px rgba(0,0,0,0.2);
$shadowLight: 0 0 10px 2px rgba(0,0,0,0.1);

$themeMaxWidth:   1024px;

@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;600&display=swap');


// mixins
@mixin center_content{
    display: flex;
    justify-content: center;
    align-items: center;
}



