/* Footer Component Styles */

@use '../../../scss/theme' as *;
@use '../../../scss/utils/media' as *;

.site-footer{
    .content-container{
        margin: 0 auto;
        padding: 0 16px;
        padding-top: 80px;
        max-width: $themeMaxWidth;
        .navbar{
            display: grid;
            grid-template-columns: 1fr 3fr;
            gap: 16px;
            justify-content: space-between;
            @include media_small{
                grid-template-columns: 1fr;
                justify-content: flex-end;
                justify-items: flex-end;
                gap: 32px;
            }
            .social-links-container{
                @include media_small{
                    grid-area: 1 / 1 / 2 / 2;
                }
                .social-links{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    justify-content: flex-end;
                    .social-link-item{
                        padding: 8px 4px;
                        margin: 0 8px;
                        .social-link{
                            font-size: 1.25em;
                            color: $textColorWhite;
                            &:hover{
                                color: $themeColorDark;
                            }
                        }
                    }
                }
            }
        }
        .copyright{
            margin-top: 64px;
            margin-bottom: 16px;
            padding: 8px 0;
            text-align: center;
            color: $textColorWhite;
        }
    }
}

/* THEME COLORS */
body{
    &.lightTheme{ 
        .site-footer{
            .content-container{
                .navbar{
                    .social-links-container{
                        .social-links{
                            .social-link-item{
                                .social-link{
                                    color: $textColorBlack;
                                    &:hover{ color: $themeColor; }
                                }
                            }
                        }
                    }
                }
                .copyright{
                    color: $textColorBlack;
                }
            }
        }
    }
    &.darkTheme{  
        .site-footer{
            .content-container{
                .navbar{
                    .social-links-container{
                        .social-links{
                            .social-link-item{
                                .social-link{
                                    color: $textColorWhite;
                                    &:hover{ color: $themeColorDark; }
                                }
                            }
                        }
                    }
                }
                .copyright{
                    color: $textColorWhite;
                }
            }
        }
    }
}
