/* Header Component Styles */
@use '../../../scss/theme' as *;
@use '../../../scss/utils/media' as *;


// Generic logo style used in both desktop and mobile menus
.logo-container{
    width: 50px;
    img{
        opacity: 0;
        width: 100%;
        height: auto;
        transition: opacity 0.5s;
    }
}

.site-header{
    margin-bottom: 80px;
    position: fixed;
    z-index: 99;
    width: 100%;
    background: linear-gradient(45deg, $themeColorFadeDark, rgba(0,0,0,0), $themeColorFadeDark);
    background-color: $themeBlack;
    box-shadow: $shadow;
    overflow-x: hidden;
    @include media_large{
        position: relative;
        width: 100%;
        height: auto;
        background: none;
    }
    .navbar-container{
        max-width: $themeMaxWidth;
        margin: 0 auto;
        .navbar{
            padding: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .links-container{
                display: none;
                @include media_large{
                    display: block;
                }
                .links-list{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    .link-item{
                        .nav-link{
                            font-size: 1.25em;        // Change this!
                            padding: 16px;
                            display: inline-block;
                            text-decoration: none;
                            text-transform: capitalize;
                            transition: 0.25s color, 0.25s border;
                            color: $textColorWhite;
                            &:hover{
                                color: $themeColorDark;
                            }
                        }
                        .active{
                            color: $themeColorDark;
                            border-bottom: 3px solid $themeColorDark;
                        }
                    }
                }
            }
            .mobile-menu-btn-container{
                @include center_content;
                @include media_large{
                    display: none;
                }
                .mobile-menu-btn{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 32px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: flex-start;
                    align-items: flex-end;
                    background: none;
                    border: none;
                    &:hover{
                        cursor: pointer;
                    }
                    .btn-bar{
                        width: 33px;
                        height: 4px;
                        margin: 3px 0;
                        border-radius: 3px;
                        background: $themeBlack;
                        transform-origin: center;
                        transition: 0.5s all;
                        &:nth-of-type(2){
                            width: 33px * 0.7;
                            justify-self: flex-end;
                        }
                        &:nth-of-type(3){
                            width: 33px * 0.4;
                            justify-self: flex-end;
                        }
                    }
                }
            }
        }
    }
}

/* THEME COLORS */
body{
    &.lightTheme{ 
        .site-header{
            background: linear-gradient(45deg, $themeColorFade, rgba(0,0,0,0), $themeColorFade);
            background-color: $themeWhite;
            @include media_large{
                background: none;
                box-shadow: none;
            }
            .navbar-container{
                .navbar{
                    .links-container{
                        .links-list{
                            .link-item{
                                .nav-link{
                                    color: $textColorBlack;
                                    &:hover{ color: $themeColor; }
                                }
                                .active{
                                    color: $themeColor;
                                    border-bottom: 3px solid $themeColor;
                                }
                            }
                        }
                    }
                    .mobile-menu-btn-container{
                        .mobile-menu-btn{
                            .btn-bar{
                                background: $themeBlack;
                            }
                        }
                    }
                }
            }
        }
    }
    &.darkTheme{  
        .site-header{
            background: linear-gradient(45deg, $themeColorFadeDark, rgba(0,0,0,0), $themeColorFadeDark);
            background-color: $themeBlack;
            @include media_large{
                background: none;
                box-shadow: none;
            }
            .navbar-container{
                .navbar{
                    .links-container{
                        .links-list{
                            .link-item{
                                .nav-link{
                                    color: $textColorWhite;
                                    &:hover{ color: $themeColorDark; }
                                }
                                .active{
                                    color: $themeColorDark;
                                    border-bottom: 3px solid $themeColorDark;
                                }
                            }
                        }
                    }
                    .mobile-menu-btn-container{
                        .mobile-menu-btn{
                            .btn-bar{
                                background: $themeWhite;
                            }
                        }
                    }
                }
            }
        }
    }
}


@include media_large{
    .site_header_navbar_mobile_menu_button_container{
        display: none;
    }

    /* MOBILE MENU */

    .mobile_menu_container{
        left: 100%;
        display: none;
    }
    /* *********** */
}
