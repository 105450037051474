@use '../../../scss/theme' as *;

$button_size: 30px;
$border_width: 5px;

#darkmode-btn-container{
    position: relative;
    width: ($button_size + $border_width) * 2;
    height: 40px;
    border-radius: 35px;
    border: $border_width solid $themeBlack;
    &:hover{
        cursor: pointer;
    }
    #darkmode-btn{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $button_size;
        height: $button_size;
        padding: 2px;
        border-radius: 35px;
        background: $themeBlack;
        transition: transform .25s;
        #icon{
            width: 100%;
            height: auto;
            path { 
                transform-origin: center;
                transition: all .5s; 
                fill: $themeWhite; 
                &#sun-icon{ transform: scale(1);}
                &#moon-icon{ transform: scale(0);}
            }
        }
    }
}



/* THEME COLORS */
body{
    &.lightTheme{
        #darkmode-btn-container{
            border: $border_width solid $themeBlack;
            #darkmode-btn{
                transform: translateX(0%);
                background: $themeWhite;
                #icon{
                    path { 
                        fill: $themeBlack; 
                        &#sun-icon{ transform: scale(0);}
                        &#moon-icon{ transform: scale(1);}
                    }
                }
            }
        }
    }
    &.darkTheme{
        #darkmode-btn-container{
            border: $border_width solid $themeWhite;
            #darkmode-btn{
                transform: translateX(100%);
                background: $themeBlack;
                #icon{
                    path { 
                        fill: $themeWhite; 
                        &#sun-icon{ transform: scale(1);}
                        &#moon-icon{ transform: scale(0);}
                    }
                }
            }
        }
    }
}
